<template>
  <div class="evaluate">
    <teacherInfo />
    <div class="rightCont">
      <div class="tabTit">
        <div class="text">全班幼儿单课次评分</div>
        <!-- <div class="btn-add btn-blue tab" @click="$router.go(-1)">&lt;&nbsp;返回</div> -->
        <div class="btn btn-add tab" @click="submit">提交评分</div>
      </div>

      <div class="custTab">
        <table class="layui-table">
          <thead>
            <tr>
              <th class="th_01">编号</th>
              <th class="th_01">姓名</th>
              <th class="th_01">班级</th>
              <th class="th_01">课次</th>
              <th></th>
              <th class="th_02">课堂专注</th>
              <th class="th_02">动作规范</th>
              <th class="th_02">互助合作</th>
              <th class="th_02">情绪表现</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ common.PrefixZero(index + 1, 3) }}</td>
              <td>{{ item.stuName }}</td>
              <!-- <td>
                {{ item.gradeName == 0 ? "小班" : item.type == 1 ? "中班" : "大班" }}
              </td> -->
              <td>
                {{ item.gradeName }}
              </td>
              <td>{{ item.lessonName }}</td>
              <td></td>
              <td>
                <div class="star">
                  <el-rate
                    v-model="item.startKtzz"
                    :disabled="item.evaluatestatus === '1'"
                  ></el-rate>
                </div>
              </td>
              <td>
                <div class="star">
                  <el-rate
                    v-model="item.startDzgf"
                    :disabled="item.evaluatestatus === '1'"
                  ></el-rate>
                </div>
              </td>
              <td>
                <div class="star">
                  <el-rate
                    v-model="item.startHzhz"
                    :disabled="item.evaluatestatus === '1'"
                  ></el-rate>
                </div>
              </td>
              <td>
                <div class="star">
                  <el-rate
                    v-model="item.startQxbx"
                    :disabled="item.evaluatestatus === '1'"
                  ></el-rate>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import teacherInfo from "@/components/course/teacherInfo.vue";
export default {
  name: "evaluateComponent",
  components: {
    teacherInfo,
  },
  data() {
    return {
      value1: "",
      list: [],
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        gradeId: this.$route.query.gradeId,
        semesterId: this.$store.state.userInfo.semesterId,
        videoId: this.$route.query.videoId,
      };
      // this.api.pes.findSoreStuList({ id: 29 }).then((res) => {
      this.api.pes.findClassScore(data).then((res) => {
        console.log(res, "1111111111111111111");
        this.list = res.data.map((item) => {
          if (item.evaluatestatus == "0") {
            if (!item.startKtzz) item.startKtzz = 0;
            if (!item.startHzhz) item.startHzhz = 0;
            if (!item.startDzgf) item.startDzgf = 0;
            if (!item.startQxbx) item.startQxbx = 0;
          }

          return {
            ...item,
          };
        });
      });
    },
    submit() {
      // let data = {
      //   classRecordId: this.$route.query.id,
      //   videoId: this.$route.query.videoId,
      //   soreStuMapList: [],
      // };
      // data.soreStuMapList = this.list.map((item) => {
      //   return {
      //     stuId: item.stuId,
      //     userName: item.userName,
      //     type: item.type,
      //     lesson: parseInt(item.lesson.replace(/[^0-9]/gi, "")),
      //     starCount: item.start1 + item.start2 + item.start3 + item.start4,
      //     gradeId: item.gradeId,
      //     gradeName: item.gradeName,
      //   };
      // });
      let data = JSON.parse(JSON.stringify(this.list));
      data = data.filter((item) => {
        return (
          item.evaluatestatus == "0" &&
          (!!item.startKtzz ||
            !!item.startHzhz ||
            !!item.startDzgf ||
            !!item.startQxbx)
        );
      });
      data.forEach((item) => {
        if (item.startKtzz) item.startKtzz = item.startKtzz.toString();
        if (item.startHzhz) item.startHzhz = item.startHzhz.toString();
        if (item.startDzgf) item.startDzgf = item.startDzgf.toString();
        if (item.startQxbx) item.startQxbx = item.startQxbx.toString();
      });
      this.api.pes.saveClassSore(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "评价成功,立即跳转",
          }).isShow();
          setTimeout(() => {
            this.$router.push({ path: "/school/home" });
          }, 2000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluate {
  @include flex(row, space-between, flex-start);
  margin-top: 100px;
  .rightCont {
    flex: 1;
    font-size: 18px;
    margin-bottom: 100px;
    box-sizing: border-box;
    padding-right: 60px;
    margin-left: 60px;
    .tabTit {
      min-height: 90px;
      box-sizing: border-box;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .btn-add,
      .btn-blue {
        padding: 0 40px;
        height: 38px;
        line-height: 38px;
        font-size: 22px;
        border-radius: 2px;
        display: inline-block;
        vertical-align: middle;
        border: none;
        text-align: center;
      }

      .btn-add {
        background: #cb1b31;
        color: #fff;
      }
      .btn-blue {
        background: #228cec;
        color: #fff;
      }
      .text {
        line-height: 30px;
        font-weight: 700;
        color: #cb1b31;
        font-size: 30px;
      }
    }
    .custTab {
      /* padding: 0rem .5rem; */
      position: relative;
      margin-bottom: 40px;
      .layui-table {
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #fff;
        color: #666;
        border: none;
        border-collapse: collapse;
        border-spacing: 0;
      }
      thead {
        position: relative;
        th {
          position: relative;
          text-align: left;
          background: #cc1a30;
          line-height: 30px;
          font-weight: 700;
          color: #fff;
          font-size: 20px;
          padding: 15px;
          border: none;
        }
      }
      tbody {
        text-align: left;
        border-bottom: solid 3px #cc1a30;
        tr {
          border-bottom: solid 1px #cb1b31;
        }
        td {
          color: #3e3e3e;
          font-size: 18px;
          line-height: 30px;
          padding: 15px;
          border: none;
          position: relative;
        }
      }
    }
  }
}
</style>
